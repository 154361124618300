.accordion {
  margin-left: -28px;
  margin-right: -28px;

  @screen smaller {
    margin-left: -40px;
    margin-right: -40px;
  }

  @screen sm2 {
    margin-left: -60px;
    margin-right: -60px;
  }

  @screen md {
    margin-left: 0;
    margin-right: 0;
  }
}

.accordion__item {
  width: 100%;
  border-bottom: 1px solid #525a63;

  padding-left: 28px;
  padding-right: 28px;

  @screen smaller {
    padding-left: 40px;
    padding-right: 40px;
  }

  @screen sm2 {
    padding-left: 60px;
    padding-right: 60px;
  }

  @screen md {
    padding-left: 0;
    padding-right: 0;
  }
}

.accordion__title {
  text-align: left;
  font-size: clamp(18px, 1.3vw, 24px);
  font-weight: 600;
  letter-spacing: 0.6px;
  font-family: Manrope;
  transition: all 0.2s ease-out;
}

.accordion__title_active {
  color: #bca36b;
}

.accordion__toggle_active_sec {
  color: black;
}

.accordion__button {
  display: flex;
  align-items: center;
  position: relative;
  padding: 30px 0;
  padding-right: 60px;
  width: 100%;

  @screen xxl {
    padding: 40px 0;
    padding-right: 60px;
  }
}

.accordion__button_small {
  padding-right: 35px;
}

.accordion__button:hover .accordion__title {
  transform: translateX(5px);
  transition: all 0.2s ease-out;
}

.accordion__toggle {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  margin: auto;

  @screen md {
    transition: all 0.3s ease-in-out;
  }

  &:after {
    content: '';
    position: absolute;
    display: block;
    width: 30px;
    height: 1px;
    background-color: white;
  }

  &:before {
    content: '';
    position: absolute;
    display: block;
    width: 30px;
    height: 1px;
    background-color: white;
    transform: rotate(90deg);
  }
}

.accordion__toggle_small {
  width: 20px;
  height: 20px;

  &:after,
  &:before {
    width: 20px;
  }
}

.accordion__toggle_black {
  &:after {
    background-color: black;
  }

  &:before {
    background-color: black;
  }
}

.accordion__toggle_active {
  transform: rotate(405deg);
}

.accordion__text {
  overflow: hidden;
  font-size: clamp(17px, 1.1vw, 20px);
  font-weight: 200;
  letter-spacing: 0.4px;

  @screen md {
    margin-right: 60px;
    transition: all 0.3s ease-out;
  }
}

.accordion__text_active {
  margin-bottom: 40px;
}

/* Safari 10.1+ (alternate method) */

@media not all and (min-resolution: 0.001dpcm) {
  @supports (-webkit-appearance: none) {
    .accordion__text {
      transition: none;
    }
  }
}
