.simple_carousel {
  position: relative;
  z-index: 9;
}

.simple_carousel_wrapper {
  position: relative;
}

.simple_carousel_item {
  flex-shrink: 0;
  cursor: grab;
}

.inner {
  display: flex;
}

.transition {
  transition: transform 0.3s ease;
}

.simple_carousel_controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.simple_carousel_bullets {
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;
  margin-top: 0;

  @screen md {
    position: absolute;
    margin: 30px;
  }
}

.simple_carousel_bullets_item {
  width: 4px;
  height: 4px;
  margin: 4px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0 0 3px rgba(black, 0.5);
  transition: transform 0.6s ease;
}

.simple_carousel_bullets_item_current {
  transform: scale(1.7);
}

.simple_carousel_bullets_item_after_current {
  transform: scale(1.4);
}

.simple_carousel_bullets_item_before_current {
  transform: scale(1.4);
}
