.planet_decor {
  background: radial-gradient(
    circle,
    #be55f0 0%,
    #446af3 33%,
    #2e61f3 39%,
    #5ad08240 50%,
    rgba(90, 208, 130, 0) 60%
  );
  opacity: 0.3;
  width: 1200px;
  height: 1200px;
  border-radius: 50%;
  flex-shrink: 0;

  @screen md {
    width: 3500px;
    height: 3500px;
  }
}

.planet_decor_wrapper {
  position: absolute;
  pointer-events: none;
  display: flex;
  width: 100%;
  justify-content: center;
}
