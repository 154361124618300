.step_sec {
}

.step_sec_text {
  display: block;
  font-size: 18px;
  font-style: italic;
  margin-bottom: 5px;
}

.step_sec_progress_bar {
  position: relative;
  width: 100px;
  height: 2px;
  background-color: #2c3745;
  border-radius: 3px;
  overflow: hidden;
}

.step_sec_active_progress_bar {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  height: 2px;
  background-image: linear-gradient(
    89.95deg,
    #be55f0 0%,
    #2e6ef3 50%,
    #5ad082 100%
  );
}
