.endless_carousel {
  display: flex;
  will-change: transform;
  justify-content: flex-start;
}

.endless_carousel_container {
  position: relative;

  @screen larger {
    overflow: hidden;
  }
}

.endless_carousel_item {
  flex-shrink: 0;
}

.transition {
  transition: transform 1s ease;
}
