.carousel_3d_controls {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.carousel_3d_controls_disabled {
  pointer-events: none;
}
