.testimonial {
  padding: 25px 28px;
  border: 1px solid #525a63;
  color: #19232f;
  margin-bottom: 20px;

  @screen lg {
    padding: 28px 44px;
    margin-bottom: 30px;
  }
}

.testimonial_small {
  border-radius: 10px;
  border: none;
  line-height: 1.7;
  background-color: white;

  @screen lg {
    padding: 32px 28px;
    margin-bottom: 30px;
  }
}

.testimonial_primary {
  transition: all 0.3s ease;

  &:hover {
    transform: translateX(5px);
    box-shadow: 5px 0 10px #dbdbdb;
    transition: all 0.3s ease;
  }
}

.testimonial_white {
  padding: 20px;
  background-color: white;
  border-radius: 7px;
  border: none;
}

.testimonial_white.testimonial {
  margin-bottom: 0;
}

.testimonial_white .testimonial__avatar {
  width: 44px;
  height: 44px;
}

.testimonial_white .testimonial__name {
  font-size: 12px;

  @screen lg {
    font-size: 14px;
  }
}

.testimonial_white .testimonial__content {
  font-size: 12px;
  margin-top: -10px;

  @screen lg {
    font-size: 13px;
  }
}

.testimonial_small .testimonial__content {
  margin-top: 16px;
  font-size: 14px;
  font-family: Manrope;
}

.testimonial_white .testimonial__star {
  width: 16px;
  height: 16px;

  @screen md {
    width: 16px;
    height: 16px;
  }
}

.testimonial_white .testimonial__link {
  margin-top: 10px;
  font-size: 11px;
}

.testimonial__avatar {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  overflow: hidden;
  float: left;
  margin-right: 20px;

  @screen md {
    width: 72px;
    height: 72px;
  }
}

.testimonial_secondary .testimonial_row {
  margin-top: 6px;
}

.testimonial_secondary {
  margin-bottom: 0;
}

.testimonial_secondary {
  color: #f6f4ee;
  width: 100%;
}

.testimonial_secondary .testimonial__link {
  font-size: 14px;
  color: #bca36b;
}

.testimonial_secondary .testimonial__location {
  svg {
    fill: #f6f4ee;
  }
}

.testimonial_secondary .testimonial__verified {
  svg {
    fill: #f6f4ee;
  }
}

.testimonial__name {
  font-size: 16px;
  font-weight: 700;
  font-family: 'Manrope';

  @screen lg {
    font-size: 20px;
  }
}

.testimonial_secondary .testimonial__name {
  font-size: 15px;

  @screen lg {
    font-size: 18px;
  }
}

.testimonial__stars {
  display: flex;
}

.testimonial_secondary .testimonial__location,
.testimonial_secondary .testimonial__verified {
  font-size: 12px;

  @screen lg {
    font-size: 13px;
  }
}

.testimonial__location {
  display: flex;
  align-items: center;
  margin-right: 12px;
  font-family: 'Manrope';
  font-size: 10px;

  @screen lg {
    font-size: 11px;
  }

  svg {
    margin-right: 8px;
    fill: #19232f;
  }
}

.testimonial__verified {
  display: flex;
  align-items: center;
  font-family: 'Manrope';
  font-size: 10px;

  @screen lg {
    font-size: 11px;
  }

  svg {
    margin-right: 8px;
    fill: #19232f;
  }
}

.testimonial__star {
  background: #00b57a;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 3px;

  @screen md {
    width: 20px;
    height: 20px;
  }

  &:last-child {
    margin-right: 0;
  }
}

.testimonial__content {
  margin-top: 8px;
  font-size: 14px;

  @screen md {
    font-size: 16px;
  }

  @screen lg {
    font-size: 19px;
  }
}

.testimonial__content_sec {
  font-size: 13px;
  font-style: italic;
  line-height: 1.3;
  text-align: center;
  font-weight: 400;

  @screen md {
    font-size: 15px;
  }

  @screen lg {
    font-size: 17px;
  }
}

.testimonial_secondary .testimonial__content {
  font-size: 13px;

  @screen md {
    font-size: 15px;
  }

  @screen lg {
    font-size: 17px;
  }
}

.testimonial__link {
  margin-top: 26px;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Manrope';
  color: #bca36b;
  transition: all 0.3s ease;

  &:hover {
    color: #debf7c;
    transition: all 0.3s ease;
  }
}

.testimonial__link_sec {
  margin-top: 16px;
  display: inline-block;
  font-size: 12px;
  font-weight: 700;
  font-family: 'Manrope';
  color: #bca36b;
  transition: all 0.3s ease;

  &:hover {
    color: #debf7c;
    transition: all 0.3s ease;
  }
}

.testimonial_big {
  display: block;
  padding: 16px 22px;
  background-color: white;
  border: none;
  border-radius: 6px;
  transition: transform 0.3s ease;
  animation: pulse 4s infinite;
}

.testimonial_big_sec {
  animation: none;
  padding: 22px;
  box-shadow: 0 0 30px rgba(black, 0.1);
}

.testimonial_big__avatar {
  float: none;
}

.testimonial_big_line {
  border-top: 1px solid #e7e7e7;
  margin-top: 15px;
  padding-top: 15px;
}

.testimonial_big .testimonial__verified {
  svg {
    fill: #8d8d8d;
  }
}

.testimonial_big .testimonial__content {
  font-size: 13px;
  line-height: 1.6;
  font-family: Manrope;
}

.testimonial_big .testimonial__avatar {
  width: 44px;
  height: 44px;
  margin-right: 12px;
}

.testimonial_big .testimonial__name {
  text-align: left;
  font-size: 13px;
}

.testimonial_big .testimonial__review {
  font-size: 12px;
  font-family: Manrope;
}

.testimonial_big .testimonial__star {
  width: 16px;
  height: 16px;
}

.testimonial_calc_height {
  height: calc(100% - 55px);
}

.testimonial_calc_height_sec {
  height: calc(100% - 40px);
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0.4);
  }
  25% {
    box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
  }
  50% {
    box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
  }
}

.testimonial_initials {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.testimonial_hover_anim {
  &:hover {
    transform: scale(1.01);
    transition: transform 0.3s ease;
  }
}
