.carousel_3d_slide {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  cursor: grab;
  /* overflow: hidden; */
  top: 0;
  display: block;
  margin: 0;
  filter: blur(4px);
  will-change: transform, opacity, filter, z-index;
  touch-action: pan-x;
}

.carousel_3d_slide img {
  width: 100%;
}

.carousel_3d_slide_current {
  opacity: 1 !important;
  visibility: visible !important;
  z-index: 999;
  filter: blur(0px);
}
