.carousel_3d_container {
  min-height: 1px;
  width: 100%;
  position: relative;
  z-index: 0;
  overflow: hidden;
  box-sizing: border-box;
}
.carousel_3d_slider {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  -moz-perspective: 1000px;
  perspective: 1000px;
  z-index: 99;
}
