.control_input_input {
  width: 100%;
  padding: 10px 12px;
  color: black;
  border: 1px solid #cccccc;
  background: white;
  border-radius: 4px;
  font-size: 13px;
  outline: none;

  &:focus {
    /* border: 1px solid black; */
  }

  @screen md {
    font-size: 16px;
  }

  &:disabled {
    background-color: #f3f3f3;
    color: #9ca0aa;
    border: 1px solid #f3f3f3;
  }
}

/* Change the white to any color */
.control_input_input_dark:-webkit-autofill,
.control_input_input_dark:-webkit-autofill:hover,
.control_input_input_dark:-webkit-autofill:focus,
.control_input_input_dark :-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #37404c inset !important;
  -webkit-text-fill-color: #9ca0aa !important;
}

.control_input_label {
  color: black;
  font-weight: 600;
  font-size: 13px;
  display: inline-block;
  margin-bottom: clamp(5px, 1vw, 10px);

  @screen md {
    font-size: 16px;
    /* margin-bottom: 10px; */
  }
}

.control_input_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  right: 50px;
  height: 28px;
  width: 28px;
  transform: translateY(-50%);
}

.control_input {
  font-family: 'Manrope';
  position: relative;
  margin-bottom: clamp(16px, 1.5vw, 22px);
}

.control_input_label_dark {
  color: white;
}

.control_input_input_dark {
  border: 1px solid #cccccc;
  background-color: #262d36;
  color: #9ca0aa;

  &:focus {
    /* border: 1px solid white; */
    background-color: #37404c;
    caret-color: #5ad082;
  }

  &:disabled {
    background-color: #262d36;
    color: #9ca0aa;
    border: 1px solid #3e3e3e;
  }
}

.control_input_rounded {
  border-radius: 30px;
  padding: 10px 20px;
}

.control_input_invalid {
  border: 1px solid red;
}

.control_input_valid {
  border: 1px solid #4fbb6a;
}

.control_input_invalid.control_input_input,
.control_input_valid.control_input_input {
  padding-right: 40px;
}

.control_input_error {
  position: absolute;
  left: 0;
  font-size: 10px;
  color: red;
  letter-spacing: 0.5px;
  line-height: 1;
  margin-top: 4px;

  @screen md {
    font-size: 12px;
  }
}

.control_input_wrapper {
  position: relative;
}

.control_input_icon {
  position: absolute;
  top: 0;
  right: 16px;
  bottom: 0;
  margin: auto;
  height: 20px;
  width: 20px;
}
