.modal_container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999;
  overflow: scroll;
}

.modal_bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.modal {
  display: flex;
  position: relative;
  width: 100%;
  padding: 30px;
  background-color: white;

  @screen md {
    padding: 60px;
  }
}
