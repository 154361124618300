.carousel_3d_bullets {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 24px;

  @screen md {
    margin: 30px;
  }
}

.carousel_3d_bullets_item {
  width: 4px;
  height: 4px;
  margin: 4px;
  border-radius: 50%;
  background-color: white;
  transition: transform 0.6s ease;
}

.carousel_3d_bullets_item_black {
  background-color: black;
}

.carousel_3d_bullets_item_gray {
  background-color: #d7d7d7;
}

.carousel_3d_bullets_item_current {
  transform: scale(1.7);
}

.carousel_3d_bullets_item_after_current {
  transform: scale(1.4);
}

.carousel_3d_bullets_item_before_current {
  transform: scale(1.4);
}
