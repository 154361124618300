.cta {
  position: relative;
  padding-top: clamp(160px, 15vw, 260px);
  padding-bottom: clamp(60px, 10vw, 170px);
}

.cta__container {
  z-index: 9;
  position: relative;

  @screen sm2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}

.cta__title {
  font-size: clamp(28px, 2.3vw, 48px);
  font-weight: 800;
  font-family: Manrope;
  margin-bottom: clamp(30px, 3.5vw, 50px);
  line-height: 1.3;
  text-align: left;

  @screen sm2 {
    text-align: center;
  }
}

.cta__title_center {
  text-align: center;
}
