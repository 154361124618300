.switcher {
  display: flex;
  border: 2px solid white;
  position: relative;
  border-radius: 3px;
}

.switcher_light {
  border: 2px solid gray;
}

.switcher_indicator {
  width: 50%;
  height: 100%;
  display: block;
  background: white;
  position: absolute;
  transition: all 0.3s ease;
}

.switcher_control {
  position: relative;
  width: 100%;
  padding: 10px 0;
  display: flex;
  justify-content: center;
}

.switcher_input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.switcher_label {
  transition: all 0.3s ease;
  color: white;
  font-size: 12px;
  font-family: Manrope;
  font-weight: 600;

  @screen md {
    font-size: 15px;
  }
}

.switcher_label_active {
  color: black !important;
}
