.hero {
  position: relative;
  width: 100%;
  justify-content: center;
  padding-top: 100px;

  @screen xl {
    /* padding-top: 200px;
    padding-bottom: 160px; */
  }
}

.hero_sec {
  position: relative;
  width: 100%;
  justify-content: center;
  padding-top: 100px;

  @screen xl {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

.hero_screen_height {
  height: 100vh;
  min-height: 920px;
  align-items: center;
}
